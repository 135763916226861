import Horizon65Logo from "Assets/img/brands/logo.svg";
import useReduxActions from "Hooks/useReduxActions";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane
} from "reactstrap";
import Error from "../../components/Errors";
import { NavButton } from "../../components/NavButton";
import { EmailSignInForm } from "./EmailSignInForm";
import { PhoneSignInForm } from "./PhoneSignInForm";
import OtpInput from 'react-otp-input';
import parsePhoneNumber, { validatePhoneNumberLength } from 'libphonenumber-js'

const handleSubmit = (data, functionHandler, history) => (event) => {
  event.preventDefault();
  functionHandler(data, history);
};

const pushHistory = (history, path) => history.push(path);

const SIGNIN_STEPS = {
  credentials: "credentials",
  otp: "otp"
}

const SIGNIN_METHODS = {
  email: "1",
  phone: "2"
}

const SignIn = ({ errors, isLoading, hasSentOTP }) => {
  const [user, setUser] = useState({ email: "", phone: "" });
  const { t } = useTranslation();
  const history = useHistory();
  const { login, clearError, sendOTP, verifyOTP } = useReduxActions();
  const [signInMethod, setSignInMethod] = useState(SIGNIN_METHODS.email)
  const [currentStep, setCurrentStep] = useState(SIGNIN_STEPS.credentials)
  const [token, setToken] = useState('')

  const handleChange = useCallback((fieldname) => (event) => {
    typeof event?.persist === 'function' && event.persist()
    setUser(user => ({ ...user, [fieldname]: event?.target?.value ?? event }))
  }, []);

  useEffect(() => {
    if (hasSentOTP) setCurrentStep(SIGNIN_STEPS.otp)
    console.log("hasSentOTP", hasSentOTP)
  }, [hasSentOTP])

  console.log("user.phone: ", user.phone)
  console.log("validation result: ", parsePhoneNumber(user.phone))

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <img src={Horizon65Logo} style={{ marginBottom: 20, maxWidth: 300, width: '80%' }} alt="Horizon65 logo" />
        <h2>{t("Welcome")}</h2>
        <p className="lead">{t("Sign in to your account to continue")}</p>
      </div>

      <Card>
        <CardBody>
          <Error errors={errors} clearError={clearError} />
          {currentStep === SIGNIN_STEPS.credentials ? (
            <>
              <div className="m-sm-4">
                <div className="text-center">{t("Sign in using")}</div>
                <Nav tabs className="mx-auto mb-4" style={{ width: "fit-content" }}>
                  <NavButton
                    value={SIGNIN_METHODS.email}
                    onSelect={setSignInMethod}
                    label="Email"
                    isSelected={signInMethod === SIGNIN_METHODS.email}
                  />
                  <NavButton
                    value={SIGNIN_METHODS.phone}
                    onSelect={setSignInMethod}
                    label={t("phone_number")}
                    isSelected={signInMethod === SIGNIN_METHODS.phone}
                  />
                </Nav>
                <TabContent activeTab={signInMethod}>
                  <TabPane tabId="1">
                    <EmailSignInForm handleChange={handleChange} onSubmit={handleSubmit({ email: user.email }, sendOTP, history)} isLoading={isLoading} />
                  </TabPane>
                  <TabPane tabId="2">
                    <PhoneSignInForm handleChange={handleChange} onSubmit={handleSubmit({ phone: `+${user.phone}` }, sendOTP, history)} isLoading={isLoading} />
                  </TabPane>
                </TabContent>
              </div>
              <div className="text-center mt-3">
                <hr />
                <Button
                  onClick={() => pushHistory(history, "/auth/sign-up")}
                  color="grey"
                  size="lg"
                >
                  {t("New here? Create an account")}
                </Button>
              </div>
            </>
          ) : (
            <div className="m-sm-4">
              <div className="text-center mb-4">{signInMethod === SIGNIN_METHODS.email ? t("Enter the code sent to your email") : t("Enter the code sent to your phone")}</div>
              <OtpInput
                containerStyle={{ justifyContent: "space-evenly" }}
                inputStyle={{ width: "2rem", height: "2.5rem", fontSize: "1.5rem" }}
                value={token}
                onChange={setToken}
                numInputs={6}
                shouldAutoFocus={true}
              />
              <div className="text-center mt-4">
                <Button color="primary" size="lg" disabled={isLoading} onClick={() => verifyOTP(signInMethod === SIGNIN_METHODS.email ? { email: user.email, token } : { phone: `+${user.phone}`, token })}>
                  {!isLoading ? t("Confirm") : t("loading")}
                </Button>
              </div>
            </div>)}

        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStateProps = (state) => ({ errors: state.errors, isLoading: state.session.loading, hasSentOTP: state.session.hasSentOTP });

export default connect(mapStateProps, null)(SignIn);
