import { createActionTypes } from "Utils/index";

// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";

export const SIDEBAR_VISIBILITY = createActionTypes("SIDEBAR_VISIBILITY", [
  "TOGGLE",
  "SHOW",
  "HIDE",
]);

export const SIDEBAR_RIGHT_ENABLE = "SIDEBAR_RIGHT_ENABLE";
export const SIDEBAR_RIGHT_DISABLE = "SIDEBAR_RIGHT_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// Session

export const LOGIN_REQUEST = "SESSION/LOGIN_REQUEST";
export const SEND_OTP_REQUEST = "SESSION/SEND_OTP_REQUEST";
export const SEND_OTP_REQUEST_SUCCESS = "SESSION/SEND_OTP_REQUEST_SUCCESS";
export const SEND_OTP_REQUEST_FAILURE = "SESSION/SEND_OTP_REQUEST_FAILURE";
export const VERIFY_OTP_REQUEST = "SESSION/VERIFY_OTP_REQUEST";
export const SIGNUP_REQUEST = "SESSION/SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SESSION/SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SESSION/SIGNUP_FAILURE";
export const UPLOAD_AVATAR_REQUEST = "SESSION/UPLOAD_AVATAR_REQUEST";
export const SMS_CONFIRMATION_REQUEST = "SESSION/SMS_CONFIRMATION_REQUEST";
export const CONFIRM_SMS_REQUEST = "SESSION/CONFIRM_SMS_REQUEST";
export const UPDATE_PROFILE_REQUEST = "SESSION/UPDATE_PROFILE_REQUEST";
export const LOGOUT = "SESSION/LOGOUT";
export const AUTHENTICATION_REQUEST = "SESSION/AUTHENTICATION_REQUEST";
export const AUTHENTICATION_SUCCESS = "SESSION/AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAILURE = "SESSION/AUTHENTICATION_FAILURE";

// Errors

export const NEW_ERROR = "ERRORS/NEW_ERROR";
export const CLEAR_ERROR = "ERRORS/CLEAR_ERROR";

// Documents

export const DOCUMENTATION_SUCCESS = "DOCUMENTS/DOCUMENTATION_SUCCESS";
export const DOCUMENTATION_FAILURE = "DOCUMENTS/DOCUMENTATION_FAILURE";
export const DOCUMENTATION_REQUEST = "DOCUMENTS/DOCUMENTATION_REQUEST";
export const FILE_UPLOAD_REQUEST = "DOCUMENTS/FILE_UPLOAD_REQUEST";
export const FILE_DELETE_REQUEST = "DOCUMENTS/FILE_DELETE_REQUEST";

// Users

export const USERS_SUCCESS = "USERS/USERS_SUCCESS";
export const USERS_FAILURE = "USERS/USERS_FAILURE";
export const USERS_REQUEST = "USERS/USERS_REQUEST";
export const RESET_PASSWORD_REQUEST = "USERS/RESET_PASSWORD_REQUEST";
export const REQUEST_RESET_PASSWORD_REQUEST =
  "USERS/REQUEST_RESET_PASSWORD_REQUEST";

// Admin operations on Documents

export const API_HOUSEHOLD = createActionTypes("API_HOUSEHOLD", [
  "REQUEST",
  "RESET",
  "SUCCESS",
  "FAILURE",
  "SET",
]);
