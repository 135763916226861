import * as types from "../constants";

const initialState = [];

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.DOCUMENTATION_SUCCESS:
      return actions.response;
    case "@APP/RESET":
      return initialState;
    default:
      return state;
  }
}
