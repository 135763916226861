import React, { useState, useEffect, useContext } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  CardText,
  Spinner,
  UncontrolledCollapse,
} from "reactstrap";
import sortById from "Utils/index";
import { MoreVertical } from "react-feather";
import FileUploader from "Components/FileUploader";
import { AdminStoreContext } from "Admin/store";

const GridElement = ({ children }) => <CardBody>{children}</CardBody>;
const borderColors = {
  rejected: "#DAA49A",
  archived: "#FFFFFF",
  approved: "#58A4B0",
  requested: "#A9BCD0",
};

const Note = ({ title, content }) => {
  if (content?.length > 0) {
    return (
      <Card
        style={{
          borderRadius: 0,
          marginTop: 20,
        }}
      >
        <CardBody
          style={{
            background: "#f4f7fc",
            borderLeft: "1px solid",
            borderRadius: 0,
            /* margin-top: 10px; */
          }}
        >
          <strong>{title}:</strong>
          <CardText>{content}</CardText>
        </CardBody>
      </Card>
    );
  } else {
    return <div />;
  }
};

const AdminDocument = (props) => {
  const { state, actions } = useContext(AdminStoreContext);

  const { session } = props;
  const [notes, setNote] = useState({});

  const inputRef = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isLoading, setLoading] = useState(false);

  let users = state.household?.data?.users || [];
  const changeHandler = (files) => {
    let selectedFile = files[0];
    setSelectedFile(files[0]);
    setIsFilePicked(true);
    setLoading(true);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedFile, selectedFile.name);

    // dispatch the action
    actions.uploadFile({
      file: formData,
      documentID: props.document.id,
      householdId: props.householdId,
    });
  };

  useEffect(() => {
    setSelectedFile(null);
    setIsFilePicked(false);
    setLoading(false);
  }, [props.document]);

  const handleSubmitNote = (id) => {
    actions.annotateDocument({
      userId: session.id,
      documentId: id,
      note: notes[id],
      householdId: props.householdId,
    });
    toggle(id);
  };

  const handleSubmitFileNote = (id) => {
    actions.annotateFile({
      fileId: id,
      userId: props.document.user_id,
      documentId: props.document.id,
      note: notes[id],
      householdId: props.householdId,
    });
    toggle(id);
  };

  const [openModals, setOpenModals] = useState({});

  const toggle = (id) => {
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [id]: !openModals[id] })
    );
  };

  const handleChangeNote = (notes, field) => (e) => {
    setNote(Object.assign({}, notes, { [field]: e.target.value }));
  };

  return (
    <Card
      style={{ borderLeft: `15px solid ${borderColors[props.document.state]}` }}
    >
      <CardHeader>
        <div className="card-actions float-right">
          <UncontrolledDropdown className="d-inline-block">
            <DropdownToggle tag="a">
              <MoreVertical />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                data-testid={props.document.id}
                onClick={() => toggle(props.document.id)}
              >
                Add a note
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  actions.approveDocument({
                    userId: session.id,
                    documentId: props.document.id,
                    householdId: props.householdId,
                  })
                }
              >
                Approve
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  actions.rejectDocument({
                    userId: session.id,
                    documentId: props.document.id,
                    householdId: props.householdId,
                  })
                }
              >
                Reject
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  actions.archiveDocument({
                    userId: session.id,
                    documentId: props.document.id,
                    householdId: props.householdId,
                  })
                }
              >
                Archive
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <CardTitle tag="h5" className="mb-0">
          <a
            id={`collapse${props.document.id}`}
            href={`#collapse${props.document.id}`}
            style={{ textDecoration: "none" }}
          >
            {props.document.name} ({props.document.state}){"  "}
          </a>
        </CardTitle>
      </CardHeader>
      <UncontrolledCollapse
        ref={inputRef}
        toggler={`#collapse${props.document.id}`}
        defaultOpen={false}
      >
        <Col md="12">
          <Row>
            <Col md="6">
              <GridElement>
                <Note content={props.document.note} title="Note" />
              </GridElement>
            </Col>
            <Col md="6">
              <GridElement>
                <FileUploader
                  changeHandler={changeHandler}
                  isFilePicked={isFilePicked}
                  selectedFile={selectedFile}
                />
                <div>
                  <br />
                  {"  "}
                  {isLoading && (
                    <Spinner size="sm" color="Info" className="mr-2" />
                  )}
                </div>
              </GridElement>
            </Col>
          </Row>
        </Col>
        <Modal
          data-testid={`modal:${props.document.id}`}
          isOpen={openModals[props.document.id]}
          toggle={() => toggle(props.document.id)}
        >
          <ModalHeader toggle={() => toggle(props.document.id)}>
            {props.document.name} ({props.document.state})
          </ModalHeader>
          <ModalBody className="text-center m-3">
            <Form onSubmit={() => handleSubmitNote(props.document.id)}>
              <FormGroup>
                <Input
                  type="textarea"
                  name="textarea"
                  rows="3"
                  defaultValue={notes[props.document.id] || props.document.note}
                  onChange={handleChangeNote(notes, props.document.id)}
                  placeholder="Your note"
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggle(props.document.id)}>
              Close
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => handleSubmitNote(props.document.id)}
            >
              Save note
            </Button>
          </ModalFooter>
        </Modal>
        <CardBody>
          <Form>
            <Row>
              <Col md="12">
                <br />
                <Table striped>
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }}>Name</th>
                      <th style={{ width: "15%" }}>Uploaded at</th>
                      <th style={{ width: "12%" }}>Status</th>
                      <th style={{ width: "15%" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.document.files.sort(sortById).map((value) => {
                      return (
                        <tr key={value.id}>
                          <Modal
                            isOpen={openModals[value.id]}
                            toggle={() => toggle(value.id)}
                          >
                            <ModalHeader toggle={() => toggle(value.id)}>
                              {value.name} ({value.state})
                            </ModalHeader>
                            <ModalBody className="text-center m-3">
                              <Form
                                onSubmit={() => handleSubmitFileNote(value.id)}
                              >
                                <FormGroup>
                                  <Input
                                    type="textarea"
                                    name="textarea"
                                    rows="3"
                                    defaultValue={notes[value.id] || value.note}
                                    onChange={handleChangeNote(notes, value.id)}
                                    placeholder="Your note"
                                  />
                                </FormGroup>
                              </Form>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="secondary"
                                onClick={() => toggle(value.id)}
                              >
                                Close
                              </Button>{" "}
                              <Button
                                color="secondary"
                                onClick={() => handleSubmitFileNote(value.id)}
                              >
                                Save note
                              </Button>
                            </ModalFooter>
                          </Modal>
                          <td>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={value.src}
                            >
                              {value.name}
                            </a>
                          </td>
                          <td>{value.inserted_at}</td>
                          <td className="table-action">{value.state}</td>
                          <td className="table-action">
                            <UncontrolledDropdown className="d-inline-block">
                              <DropdownToggle tag="a">
                                <MoreVertical />
                              </DropdownToggle>
                              <DropdownMenu right>
                                <DropdownItem onClick={() => toggle(value.id)}>
                                  Add a note
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    actions.approveFile({
                                      userId: session.id,
                                      fileId: value.id,
                                      householdId: props.householdId,
                                    })
                                  }
                                >
                                  Approve
                                </DropdownItem>
                                {users.map((user) => (
                                  <DropdownItem
                                    onClick={() =>
                                      actions.askToSign({
                                        fileId: value.id,
                                        householdId: props.householdId,
                                        userId: user.id,
                                        documentId: props.document.id,
                                      })
                                    }
                                  >
                                    Ask to {user.first_name} sign
                                  </DropdownItem>
                                ))}

                                <DropdownItem
                                  onClick={() =>
                                    actions.rejectFile({
                                      householdId: props.householdId,
                                      userId: session.id,
                                      fileId: value.id,
                                    })
                                  }
                                >
                                  Reject
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    actions.archiveFile({
                                      userId: session.id,
                                      householdId: props.householdId,
                                      fileId: value.id,
                                    })
                                  }
                                >
                                  Archive
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  );
};
export default AdminDocument;
