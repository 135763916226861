import React from "react";
import PropTypes from "prop-types";
import de from "Assets/img/brands/de.svg";
import en from "Assets/img/brands/en.svg";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const currentLanguage = { de: de, en: en };
const otherIcon = { en: de, de: en };
const changeToLanguage = { en: "de", de: "en" };
const changeToLanguageName = { en: "Deutsch", de: "English" };

const SwitchLanguage = ({ handleChangeLanguage, language }) => {
  return (
    <UncontrolledDropdown nav inNavbar className="ml-lg-1">
      <DropdownToggle id="de" nav caret>
        <img width="24" src={currentLanguage[language]} />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => handleChangeLanguage(changeToLanguage[language])}
        >
          <img width="24" src={otherIcon[language]} /> {"   "}{" "}
          {changeToLanguageName[language]}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

SwitchLanguage.propTypes = {
  handleChangeLanguage: PropTypes.func.isRequired,
  language: PropTypes.oneOf(["en", "de"]),
};
export default SwitchLanguage;
