import React, { useState } from "react";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import useReduxActions from "Hooks/useReduxActions";
import Error from "Components/Errors";
import { useTranslation } from "react-i18next";
import Horizon65Logo from "Assets/img/brands/logo.svg";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import api from "Utils/api";
import { toastr } from "react-redux-toastr";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from "reactstrap";

const handleChange = (user) => (name) => (event) => {
  user[name] = event.target.value;
};

const handleToggleTandC = (user) => (name) => (event) => {
  if (!user[name]) {
    user[name] = new Date()
  }
};

const handleChangeDate = (user) => (name) => (event) => {
  const [day, month, year] = event.target.value.split(".");
  if (Date.UTC(year, month - 1, day)) {
    user[name] = new Date(Date.UTC(year, month - 1, day));
  }
};

const handleSubmit = (functionHandler) => (event) => {
  event.preventDefault();
  functionHandler();
};

const PartnershipForm = ({ errors }) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const { partner } = useParams();
  const title = urlParams.get("title")
  const subtitle = urlParams.get("subtitle")
  const logo = urlParams.get("logo")
  const return_to = urlParams.get("return_to")
  const emailRequired = urlParams.get("email_required")
  const phoneRequired = urlParams.get("phone_required")
  const emailHidden = urlParams.get("email_hidden") == "true"
  const birthDateHidden = urlParams.get("birthdate_hidden") == "true"
  const phoneHidden = urlParams.get("phone_hidden") == "true"

  const { i18n } = useTranslation();
  const { clearError } = useReduxActions();
  const [isLoading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const setPhone = (phone) => {
    user["phone"] = phone
  }

  const captureLead = async () => {
    if (phoneRequired && user.phone?.length <= 6) {
      toastr.error("Error", t("partner_form.errors.invalid_phone_number"));
      return false
    }
    setLoading(true);
    try {
      const res = await api.post("/capture-lead", user);
      if (res.data?.errors) {
        toastr.error("Error", res.data?.errors);
      }
      if (res.data?.ok) {
        setSent(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [user] = useState({
    email: "",
    language: i18n.language,
    partner: partner,
    app_version: "web_app",
    phone: null
  });

  const { t } = useTranslation();

  if (sent) {
    return_to && window.location.replace(return_to)
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <img src={logo || Horizon65Logo} style={{ marginBottom: 20, maxWidth: 300, width: '80%' }} />
          <h1 className="h2">{title || t("partner_form.get_started")}</h1>
          <p className="lead">{subtitle || t("partner_form.start_using_horizon65_today")}</p>
        </div>

        <Card style={{ marginTop: 40 }}>
          <CardBody >
            <center style={{ padding: 40 }}>{t("partner_form.we_will_get_back_to_you")}</center>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <img src={logo || Horizon65Logo} style={{ marginBottom: 20, maxWidth: 300, width: '80%' }} />
        <h1 className="h2">{title || t("partner_form.get_started")}</h1>
        <p className="lead">{subtitle || t("partner_form.start_using_horizon65_today")}</p>
      </div>
      <Card>
        <CardBody>
          <Error errors={errors} clearError={clearError} />
          <div className="m-sm-4">
            <Form onSubmit={handleSubmit(captureLead)}>
              <FormGroup>
                <Label>{t("partner_form.name")}</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="name"
                  autoFocus
                  required
                  onChange={handleChange(user)("first_name")}
                  placeholder={t("partner_form.name")}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t("partner_form.surname")}</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="last_name"
                  required
                  onChange={handleChange(user)("last_name")}
                  placeholder={t("partner_form.surname")}
                />
              </FormGroup>
              {!phoneHidden && <FormGroup>
                <Label>{t("phone_number")}</Label>
                <PhoneInput
                  inputProps={{
                    className: "big-phone-input",
                    name: "phone",
                    required: true,
                  }}
                  country={"de"}
                  inputStyle={{ border: '1px solid #ced4da', borderRadius: '0.3rem' }}
                  onChange={(phone) => setPhone(phone)}
                />
              </FormGroup>}
              {!birthDateHidden && <FormGroup>
                <Label>{t("partner_form.birth_date")}</Label>
                <InputMask
                  onChange={handleChangeDate(user)("birth_date")}
                  mask="99.99.9999"
                  bsSize="lg"

                >
                  {(inputProps) => <Input {...inputProps} type="text" />}
                </InputMask>
                <span className="text-muted">
                  {t("partner_form.birth_date_helper_text")}
                </span>
              </FormGroup>
              }
              {!emailHidden && <FormGroup>
                <Label>E-mail</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  required={emailRequired}
                  onChange={handleChange(user)("email")}
                  placeholder={t("Enter your email")}
                />
              </FormGroup>}
              <FormGroup check>
                <Label check>
                  <Input
                    onChange={handleToggleTandC(user)("terms_of_service_accepted_at")}
                    type="checkbox"
                    required
                  />{" "}
                  <a style={{ color: 'black' }} href="https://www.horizon65.com/privacy-policy" target="_blank">{t("partner_form.accept_terms_and_conditions")}</a>
                </Label>
              </FormGroup>
              <div className="text-center mt-3">
                <Button type="submit" color="primary" size="lg">
                  {isLoading ? t("loading") : t("partner_form.request_info")}
                  {isLoading ? (
                    <Spinner
                      style={{ width: "0.7rem", height: "0.7rem" }}
                      type="grow"
                      color="light"
                    />
                  ) : null}
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStateProps = (state) => ({
  user: state.user,
  errors: state.errors,
});

export default connect(mapStateProps, null)(PartnershipForm);
