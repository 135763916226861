import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import { Badge, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { sidebarRoutes } from "../routes/index";
import logo from "../assets/img/brands/h66_b-w.svg";
import avatar from "../assets/img/avatars/avatar.png";
import useReduxActions from "Hooks/useReduxActions";

const initOpenRoutes = (location, household) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  sidebarRoutes.forEach((funRoute, index) => {
    const route = funRoute(household);
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2"
              />{" "}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}{" "}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

const Sidebar = ({
  history,
  location,
  sidebar,
  user,
  households,
  defaultHousehold,
}) => {
  const { setHousehold } = useReduxActions();
  const [openRoutes, setOpenRoutes] = useState(() =>
    initOpenRoutes(location, defaultHousehold)
  );

  React.useEffect(() => {
    initOpenRoutes(location, defaultHousehold);
  }, [location, defaultHousehold]);

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const selectHousehold = ({ value, label }) =>
    setHousehold({ name: label, id: value });
  return (
    <nav
      className={classNames(
        "sidebar",
        sidebar.isOpen || "toggled",
        !sidebar.isOnRight || "sidebar-right"
      )}
    >
      <div className="sidebar-content">
        <a
          className={classNames(
            "sidebar-brand",
            !sidebar.isOnRight || "text-right"
          )}
          href="/dashboard"
        >
          {sidebar.isOnRight || <img width="150" src={logo} alt="Horizon65" />}
          {!sidebar.isOnRight || <img width="150" src={logo} alt="Horizon65" />}
        </a>

        <div className="sidebar-user">
          {user.avatar && (
            <img
              src={user.avatar || avatar}
              className="img-fluid rounded-circle mb-2"
              alt={user.email}
            />
          )}
          {!user.avatar && (
            <img
              src={avatar}
              onClick={() => {
                history.push("/pages/settings");
              }}
              style={{ cursor: "pointer" }}
              className="img-fluid rounded-circle mb-2"
              alt={user.email}
            />
          )}
          <div className="font-weight-bold">{defaultHousehold?.name}</div>
          <hr />
          <Select
            defaultValue={defaultHousehold?.name}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={selectHousehold}
            options={households.map(({ id, name }) => {
              return { value: id, label: name };
            })}
          />
        </div>
        <ul className="sidebar-nav">
          {sidebarRoutes.map((categoryFun, index) => {
            const category = categoryFun(defaultHousehold);
            return (
              <React.Fragment key={index}>
                {category.header ? (
                  <li className="sidebar-header">{category.header}</li>
                ) : null}

                {category.children ? (
                  <SidebarCategory
                    name={category.name}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                    icon={category.icon}
                    to={category.path}
                    isOpen={openRoutes[index]}
                    onClick={() => toggle(index)}
                  >
                    {category.children.map((route, index) => (
                      <SidebarItem
                        key={index}
                        name={route.name}
                        to={route.path}
                        badgeColor={route.badgeColor}
                        badgeText={route.badgeText}
                      />
                    ))}
                  </SidebarCategory>
                ) : (
                  <SidebarItem
                    name={category.name}
                    to={category.path}
                    icon={category.icon}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                  />
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    user: store.session,
    households: store.households,
    defaultHousehold: store.defaultHousehold,
  }))(Sidebar)
);
