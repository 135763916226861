import * as errorTypes from "../constants";

export const newError = (message, id) => ({
  type: errorTypes.NEW_ERROR,
  message,
  id: id,
});

export const clearError = (id) => ({
  type: errorTypes.CLEAR_ERROR,
  id: id,
});
