import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs={8} className="text-left">
            <ul className="list-inline">
              <li className="list-inline-item">
                <Link to="dashboard" className="text-muted mr-1">
                  {t("Support")}
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="dashboard" className="text-muted mr-1">
                  {t("Privacy")}
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="dashboard" className="text-muted mr-1">
                  {t("Terms of Service")}
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="dashboard" className="text-muted">
                  {t("Contact")}
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={4} className="text-right">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} -{" "}
              <Link to="dashboard" className="text-muted">
                {t("Horizon65 GmbH")}
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
