import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { MoreVertical } from "react-feather";
import {
  Breadcrumb,
  BreadcrumbItem,
  UncontrolledDropdown,
  DropdownToggle,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Container,
  Form,
  Row,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Header from "Components/Header";
import HeaderTitle from "Components/HeaderTitle";
import sortById from "Utils/index";

import { AdminStoreContext } from "Admin/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Users = (props) => {
  function view(householdId) {
    const win = window.open(`/admin/household/${householdId}/show`, "_blank");
    win.focus();
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Users
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Form>
          <Row>
            <Col md="12">
              <br />
              <Table striped>
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>Name</th>
                    <th style={{ width: "15%" }}>Last name</th>
                    <th style={{ width: "33%" }}>Email</th>
                    <th style={{ width: "33%" }}>Phone</th>
                    <th style={{ width: "33%" }}>Households</th>
                  </tr>
                </thead>
                <tbody>
                  {props.users.sort(sortById).map((value) => {
                    return (
                      <tr key={value.id}>
                        <td>{value.first_name}</td>
                        <td>{value.last_name}</td>
                        <td>
                          {value.email}
                          {value.email_verified_at ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              fixedWidth
                              className="mr-2 align-middle"
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {value.phone}
                          {value.phone_verified_at ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              fixedWidth
                              className="mr-2 align-middle"
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="table-action">
                          <UncontrolledDropdown className="d-inline-block">
                            <DropdownToggle tag="a">
                              <MoreVertical />
                            </DropdownToggle>
                            <DropdownMenu right>
                              {value.households.map((household) => (
                                <DropdownItem
                                  onClick={() => view(household.id)}
                                >
                                  {household.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

const UsersPage = () => {
  const { state, actions } = useContext(AdminStoreContext);
  const users = state.users.data;
  const params = useParams();

  useEffect(() => {
    actions.loadUsers();
  }, []);

  useEffect(() => {
    actions.searchUsers({ query: params.query });
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Users</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/admin">Admin</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Users</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col md="12" xl="12">
          <Users users={users} />
        </Col>
      </Row>
    </Container>
  );
};

export default UsersPage;
