import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { enableDarkTheme } from "../redux/actions/themeActions";
import Wrapper from "../components/Wrapper";
import AdminSidebar from "../components/AdminSidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { AdminStoreProvider } from "Admin/store";

const Admin = ({ sidebar, children, history, theme }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (theme.currentThemeName !== "dark") {
      dispatch(enableDarkTheme());
      dispatch({ type: "@APP/RESET" });
    }
  }, []);

  return (
    <AdminStoreProvider>
      <React.Fragment>
        <Wrapper>
          {!sidebar.isOnRight && <AdminSidebar />}
          <Main>
            <Navbar history={history} />
            <Content>{children}</Content>
            <Footer />
          </Main>
          {sidebar.isOnRight && <AdminSidebar />}
        </Wrapper>
      </React.Fragment>
    </AdminStoreProvider>
  );
};

export default connect((store) => ({
  sidebar: store.sidebar,
  theme: store.theme,
}))(Admin);
