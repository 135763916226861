import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store from "./redux/store/index";
import Routes from "./routes/Routes";
import CacheBuster from "./CacheBuster";

class App extends React.Component {
  componentDidMount() {
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector(".splash") ||
      document.querySelector(".splash").classList.remove("active");
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <Provider store={store}>
              <Routes />
              <ReduxToastr
                timeOut={2000}
                newestOnTop={true}
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                closeOnToastrClick
              />
            </Provider>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
