import { call, put, takeEvery } from "redux-saga/effects";
import api from "Utils/api";
import create_UUID from "Utils/uuid";
import * as types from "../constants";
import { newError } from "Actions/errorsActions";
import { getMyDocuments } from "Actions/documentsActions";
// Fetch all my documents

function* callGetAll({ data }) {
  const { householdId } = data;
  const result = yield api.fetch(`/user/${householdId}/documents`, data);
  if (result.data && result.data.data) {
    yield put({
      type: types.DOCUMENTATION_SUCCESS,
      response: result.data.data.documents,
    });
  } else {
    const uuid = yield call(create_UUID);
    yield put({ type: types.DOCUMENTATION_FAILURE });
    yield put(newError(result.data.errors, uuid));
  }
}

// Upload a file

function* callUpload({ data }) {
  const { householdId, documentID, file } = data;
  const result = yield api.post(
    `/user/${householdId}/documents/${documentID}`,
    file
  );
  if (result.data && result.data.data) {
    yield put(getMyDocuments(data));
  } else {
    const uuid = yield call(create_UUID);
    yield put({ type: types.DOCUMENTATION_FAILURE });
    yield put(newError(result.data.errors, uuid));
  }
}

function* watchDocumentsSaga() {
  yield takeEvery(types.DOCUMENTATION_REQUEST, callGetAll);
  yield takeEvery(types.FILE_UPLOAD_REQUEST, callUpload);
}

export default [watchDocumentsSaga()];
