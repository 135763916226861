import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";
import AdminDocument from "Components/admin/Document";
import { useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import sortById from "Utils/index";

import Header from "Components/Header";
import HeaderTitle from "Components/HeaderTitle";
import AdminNewDocument from "Components/admin/NewDocument";
import { AdminStoreContext } from "Admin/store";

const Documents = (props) => {
  let { householdId } = useParams();
  const { state, actions } = useContext(AdminStoreContext);
  const documents = state.documents.data;

  useEffect(() => {
    actions.loadHouseholds();
    actions.getHousehold({ householdId });
    actions.loadDocuments({ householdId });
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Documents</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/admin">Admin</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a href="/admin/households">Households</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a href={`/admin/household/${householdId}/show`}>
              {state.household.data.name}
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Documents</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col md="12" xl="12">
          <AdminNewDocument
            householdId={householdId}
            createDocument={props.createDocument}
          />
        </Col>
      </Row>
      <Row>
        {documents.sort(sortById).map((value) => {
          return (
            <Col key={value.id} md="12" xl="12">
              <AdminDocument
                {...props}
                value={value}
                householdId={householdId}
                document={value}
                session={props.session}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

// Set default props
Documents.defaultProps = {
  documents: [],
};

const mapStateProps = (state) => ({
  session: state.session,
});

const ConnectedDocuments = connect(mapStateProps, null)(Documents);

export default ConnectedDocuments;
