import React from 'react'
import { Button, NavItem } from "reactstrap"

export const NavButton = ({ value, isSelected, onSelect, label }) => {
    return (
        <NavItem>
            <Button
                color="grey"
                onClick={() => onSelect(value)}
                className={isSelected ? 'font-weight-bolder' : ''}
            >
                {label}
            </Button>
        </NavItem>
    )
}