import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import theme from "./themeReducer";
import session from "./sessionReducers";
import errors from "./errorsReducer";
import documents from "./documentReducers";
import households from "./householdReducers";
import defaultHousehold from "./defaultHouseholdReducers";

import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  sidebar,
  theme,
  toastr,
  session,
  errors,
  documents,
  households,
  defaultHousehold,
});
