import types from "./actionTypes";

const initialHouseholdsState = {
  loading: false,
  data: [],
  error: "",
};

const householdsReducer = (state = initialHouseholdsState, action) => {
  switch (action?.type) {
    case types.HOUSEHOLDS.SET:
      return { ...state, data: action.data, loading: false };
    default:
      return state;
  }
};

const initialUsersState = {
  loading: false,
  data: [],
  error: "",
};

const usersReducer = (state = initialUsersState, action) => {
  switch (action?.type) {
    case types.USERS.SET:
      return { ...state, data: action.data, loading: false };
    default:
      return state;
  }
};

const initialDocumentsState = {
  loading: false,
  data: [],
  error: "",
};

const documentsReducer = (state = initialDocumentsState, action) => {
  switch (action?.type) {
    case types.DOCUMENTS.SET:
      return { ...state, data: action.data, loading: false };
    default:
      return state;
  }
};

const initialHouseholdState = {
  loading: false,
  data: {},
  error: "",
};

const householdReducer = (state = initialHouseholdState, action) => {
  switch (action?.type) {
    case types.HOUSEHOLD.SET:
      return { ...state, data: action.data, loading: false };
    case types.HOUSEHOLD.FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

const rootReducer = {
  documents: documentsReducer,
  users: usersReducer,
  households: householdsReducer,
  household: householdReducer,
};

export default rootReducer;
