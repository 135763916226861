import * as types from "../constants";

export const getMyDocuments = (data) => ({
  type: types.DOCUMENTATION_REQUEST,
  data,
});

export const uploadFile = (data) => ({
  type: types.FILE_UPLOAD_REQUEST,
  data,
});
