import types from "./actionTypes";
import api from "Utils/api";
import { toastr } from "react-redux-toastr";

const getHouseholds = async (dispatch, { data }) => {
  try {
    const result = await api.fetch(`/admin/households`, data);
    data = result.data.households;
    dispatch({
      type: types.HOUSEHOLDS.SET,
      data,
    });
  } catch (error) {
    console.error(error);
  }
};

const getDocuments = async (dispatch, { data }) => {
  try {
    const { householdId } = data;
    const result = await api.fetch(
      `/admin/household/${householdId}/documents`,
      data
    );
    dispatch({
      type: types.DOCUMENTS.SET,
      data: result.data.data.documents,
    });
  } catch (error) {
    console.error(error);
  }
};

const getUsers = async (dispatch, { data }) => {
  try {
    const result = await api.fetch(`/admin/users`, data);
    dispatch({
      type: types.USERS.SET,
      data: result.data.data.users,
    });
  } catch (error) {
    console.error(error);
  }
};

const searchUsers = async (dispatch, { data }) => {
  try {
    let result = {};
    if (data?.query?.length > 2) {
      result = await api.post(`/admin/users/search/${data.query}`);
    } else {
      result = await api.fetch(`/admin/users`, data);
    }
    dispatch({
      type: types.USERS.SET,
      data: result?.data?.data?.users || [],
    });
  } catch (error) {
    console.error(error);
  }
};
const approveDocument = async (dispatch, { data }) => {
  try {
    const { documentId } = data;
    await api.put(`/admin/documents/${documentId}/approve`);
    getDocuments(dispatch, { data });
  } catch (err) {
    console.error(err);
  }
};

const archiveDocument = async (dispatch, { data }) => {
  try {
    const { documentId } = data;
    await api.put(`/admin/documents/${documentId}/archive`);
    getDocuments(dispatch, { data });
  } catch (err) {
    console.log(err);
  }
};

const annotateDocument = async (dispatch, { data }) => {
  try {
    const { documentId } = data;
    await api.put(`/admin/documents/${documentId}/annotate`, data);
    getDocuments(dispatch, { data });
  } catch (err) {
    console.error(err);
  }
};

const askToSign = async (dispatch, { data }) => {
  try {
    const { documentId, fileId, userId, householdId } = data;
    const result = await api.put(
      `/admin/files/${fileId}/ask_to_sign?document_id=${documentId}&user_id=${userId}&household_id=${householdId}`
    );
    toastr.info("Opening a new tab...");
    window.open(result.data.data.external_link);
  } catch (err) {
    toastr.erro("Something went wrong, check the console for more logs");
    console.error(err);
  }
};
// Annotate files
// POST admin/documents/:document_id/files/:file_id/annotate {note: note}

const annotateFile = async (dispatch, { data }) => {
  try {
    const { documentId, fileId } = data;
    await api.post(
      `/admin/documents/${documentId}/file/${fileId}/annotate`,
      data
    );
    getDocuments(dispatch, { data });
  } catch (err) {
    console.error(err);
  }
};
//  Archive a file
// POST admin/documents/:document_id/files/:file_id/annotate {note: note}

const archiveFile = async (dispatch, { data }) => {
  try {
    const { fileId } = data;
    await api.put(`/admin/files/${fileId}/archive`, data);
    getDocuments(dispatch, { data });
  } catch (err) {
    console.error(err);
  }
};

// Reject documents
// PUT admin/documents/:document_id/reject

const rejectDocument = async (dispatch, { data }) => {
  try {
    const { documentId } = data;
    await api.put(`/admin/documents/${documentId}/reject`);
    getDocuments(dispatch, { data });
  } catch (err) {
    console.error(err);
  }
};

// Approve a file
// PUT admin/files/:file_id/approve

const approveFile = async (dispatch, { data }) => {
  try {
    const { fileId } = data;
    await api.put(`/admin/files/${fileId}/approve`);
    getDocuments(dispatch, { data });
  } catch (err) {
    console.error(err);
  }
};

// Reject a file
// PUT admin/files/:file_id/reject

const rejectFile = async (dispatch, { data }) => {
  try {
    const { fileId } = data;
    await api.put(`/admin/files/${fileId}/reject`);
    getDocuments(dispatch, { data });
  } catch (err) {
    console.error(err);
  }
};

// Upload a file as Admin

const uploadFile = async (dispatch, { data }) => {
  try {
    const { documentID, file } = data;
    await api.post(`/admin/documents/${documentID}/upload`, file);
    getDocuments(dispatch, { data });
  } catch (err) {
    console.error(err);
  }
};

// Create documents
// POST /api/admin/user/:user_id/documents/new

const createDocument = async (dispatch, { data }) => {
  try {
    const { householdId } = data;
    const result = await api.post(
      `/admin/household/${householdId}/documents/new`,
      data
    );
    if (result.data.errors) {
      dispatch({ type: types.HOUSEHOLD.FAILURE, error: result.data.errors });
    } else {
      getDocuments(dispatch, { data });
    }
  } catch (err) {
    dispatch({ type: types.HOUSEHOLD.FAILURE, error: err });
  }
};

// GET an household by id
// GET /api/admin/household/${householdId}

const getHousehold = async (dispatch, { data }) => {
  try {
    const { householdId } = data;
    const result = await api.fetch(`/admin/household/${householdId}`, data);
    data = result.data.household;
    dispatch({ type: types.HOUSEHOLD.SET, data });
  } catch (err) {
    console.error(err);
  }
};

export const applyMiddleware = (dispatch) => (action) => {
  let functions = {};
  functions[types.HOUSEHOLDS.GET] = getHouseholds;
  functions[types.HOUSEHOLD.GET] = getHousehold;
  functions[types.USERS.GET] = getUsers;
  functions[types.USERS.SEARCH] = searchUsers;
  functions[types.DOCUMENTS.GET] = getDocuments;
  functions[types.DOCUMENT.APPROVE] = approveDocument;
  functions[types.DOCUMENT.REJECT] = rejectDocument;
  functions[types.DOCUMENT.ANNOTATE] = annotateDocument;
  functions[types.DOCUMENT.CREATE] = createDocument;
  functions[types.DOCUMENT.ARCHIVE] = archiveDocument;
  functions[types.FILE.ARCHIVE] = archiveFile;
  functions[types.FILE.APPROVE] = approveFile;
  functions[types.FILE.REJECT] = rejectFile;
  functions[types.FILE.UPLOAD] = uploadFile;
  functions[types.FILE.ANNOTATE] = annotateFile;
  functions[types.FILE.ASK_TO_SIGN] = askToSign;

  let caller = functions[action.type];
  if (caller) {
    caller(dispatch, action);
  } else {
    dispatch(action);
  }
};
