import React from "react";
import SignIn from "Pages/auth/SignIn";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";

const VerifyEmail = () => {
  const { t } = useTranslation();
  React.useEffect(
    () => toastr.success(t("email_verified"), t("email_verified_message")),
    []
  );

  return <SignIn />;
};

export default VerifyEmail;
