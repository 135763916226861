import * as types from "../constants";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_ERROR:
      let newState = state;
      delete newState[action.id];
      return { ...newState };
    case types.NEW_ERROR:
      let errors = state;
      let uuid = action.id;
      errors[uuid] = action.message;
      return { ...state, ...errors };
    case "@APP/RESET":
      return initialState;
    default:
      return state;
  }
}
