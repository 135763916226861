import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import SwitchLanguage from "Components/SwitchLanguage";
import { useDispatch } from "react-redux";
import useReduxActions from "Hooks/useReduxActions";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { AdminStoreContext } from "Admin/store";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faUser,
  faTools,
  faArrowAltCircleRight,
  faStore
} from "@fortawesome/free-solid-svg-icons";

const NavbarToggle = connect((store) => ({ app: store.app }))(
  ({ dispatch }) => {
    const { toggleSidebar } = useReduxActions();
    return (
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          toggleSidebar();
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
    );
  }
);

export const NavbarSearch = ({ history, sidebar, actions }) => {
  const [searchQuery, setQuery] = useState(null);

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        history.push(`/search/${searchQuery}`);
      }}
      inline
    >
      <Input
        type="text"
        placeholder="Search user..."
        aria-label="Search"
        onChange={(event) => {
          setQuery(event.target.value);
          actions.searchUsers({ query: event.target.value });
        }}
        className={classNames(
          "form-control-lite",
          !sidebar.isOnRight || "text-right"
        )}
      />
    </Form>
  );
};

const NavbarItems = connect((store) => ({
  app: store.app,
  sidebar: store.sidebar,
  session: store.session,
}))(({ sidebar, session }) => {
  const dispatch = useDispatch();
  const actions = useReduxActions();
  let history = useHistory();
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const handleChangeLanguage = (code) => {
    // dispatch an action so that sagas could eventually pick it up and try to
    // update the profile via APIs
    dispatch({ type: "CHANGE_LANGUAGE", data: { language: code } });
    // Change the language locally anyway, maybe very needed
    // to understand what is going on
    i18n.changeLanguage(code);
  };

  return (
    <Collapse navbar>
      <Nav className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar>
        <SwitchLanguage
          language={currentLanguage}
          handleChangeLanguage={handleChangeLanguage}
        />
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <DropdownToggle nav caret>
            <FontAwesomeIcon icon={faCog} />
          </DropdownToggle>
          <DropdownMenu right>
            {session.admin && (
              <DropdownItem
                onClick={() => {
                  history.push("/admin");
                }}
              >
                <FontAwesomeIcon
                  icon={faTools}
                  fixedWidth
                  className="mr-2 align-middle"
                />
                Admin
              </DropdownItem>
            )}
            <DropdownItem
              onClick={() => {
                history.push("/pages/settings");
              }}
            >
              <FontAwesomeIcon
                icon={faUser}
                fixedWidth
                className="mr-2 align-middle"
              />
              {t("navbar.settings_title")}
            </DropdownItem>
            <DropdownItem onClick={() => actions.logout(history)}>
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                fixedWidth
                className="mr-2 align-middle"
              />
              Sign out
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
});

const NavbarComponent = connect((store) => ({
  sidebar: store.sidebar,
  user: store.session,
}))(({ sidebar, showSidebar, user }) => {
  showSidebar = showSidebar != false;
  const history = useHistory();
  const context = useContext(AdminStoreContext);

  return (
    <Navbar expand className="navbar-theme">
      {!sidebar.isOnRight ? (
        <React.Fragment>
          {showSidebar && <NavbarToggle />}
          {user.admin && (
            <NavbarSearch
              history={history}
              sidebar={sidebar}
              actions={context?.actions}
            />
          )}
          <NavbarItems />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NavbarItems />
          {showSidebar && <NavbarToggle />}
          {user.admin && (
            <NavbarSearch
              sidebar={sidebar}
              history={history}
              actions={context?.actions}
            />
          )}
        </React.Fragment>
      )}
    </Navbar>
  );
});

export default connect((store) => ({
  sidebar: store.sidebar,
}))(NavbarComponent);
