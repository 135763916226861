import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avatar from "Assets/img/avatars/avatar.png";
import logo from "Assets/img/brands/h66_b-w.svg";
import classNames from "classnames";
import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import { admin as adminRoutes } from "Routes/index";

const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};
  adminRoutes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = ({
  name,
  badgeColor,
  badgeText,
  icon,
  isOpen,
  children,
  onClick,
  to,
}) => {
  const location = useLocation();
  const getSidebarItemClass = (path) => {
    return location.pathname.indexOf(path) !== -1 ||
      (location.pathname === "/" && path === "/admin")
      ? "active"
      : "";
  };

  return (
    <li className={"sidebar-item " + getSidebarItemClass(to)}>
      <span
        data-toggle="collapse"
        className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
        onClick={onClick}
        aria-expanded={isOpen ? "true" : "false"}
      >
        <FontAwesomeIcon icon={icon} fixedWidth className="align-middle mr-2" />{" "}
        <span className="align-middle">{name}</span>
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} pill className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </span>
      <Collapse isOpen={isOpen}>
        <ul id="item" className={"sidebar-dropdown list-unstyled"}>
          {children}
        </ul>
      </Collapse>
    </li>
  );
};

const SidebarItem = ({ name, badgeColor, badgeText, icon, to, external }) => {
  let location = useLocation();
  const getSidebarItemClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const Wrapper = ({ children }) => {
    if (external) {
      return (
        <li className={"sidebar-item " + getSidebarItemClass(to)}>
          <a href={to} className="sidebar-link" activeClassName="active">
            {children}
          </a>
        </li>
      );
    } else {
      return (<li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">{children}</NavLink>
      </li>)
    }
  }

  return (
    <Wrapper>
      {icon ? (
        <React.Fragment>
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
        </React.Fragment>
      ) : (
        name
      )}{" "}
      {badgeColor && badgeText ? (
        <Badge color={badgeColor} size={18} pill className="sidebar-badge">
          {badgeText}
        </Badge>
      ) : null}
    </Wrapper>
  );
};

const Sidebar = ({ sidebar, user }) => {
  let location = useLocation();
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };
  return (
    <nav
      className={classNames(
        "sidebar",
        sidebar.isOpen || "toggled",
        !sidebar.isOnRight || "sidebar-right"
      )}
    >
      <div className="sidebar-content">
        <a
          className={classNames(
            "sidebar-brand",
            !sidebar.isOnRight || "text-right"
          )}
          href="/admin"
        >
          {sidebar.isOnRight || <img width="150" src={logo} alt="Horizon65" />}
          {!sidebar.isOnRight || <img width="150" src={logo} alt="Horizon65" />}
        </a>
        <div className="sidebar-user">
          <img
            src={user.avatar || avatar}
            className="img-fluid rounded-circle mb-2"
            alt={user.email}
          />
          <div className="font-weight-bold">
            Admin: {user.email}
            <br />
            {user.first_name} {user.last_name}
          </div>
          {/* <small>Front-end Developer</small> */}
        </div>
        <ul className="sidebar-nav">
          {adminRoutes.map((category, index) => {
            return (
              category.sidebar && (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={openRoutes[index]}
                      onClick={() => toggle(index)}
                    >
                      {category.children.map((route, index) => {
                        return (
                          route.sidebar && (
                            <SidebarItem
                              key={index}
                              name={route.name}
                              to={route.path}
                              badgeColor={route.badgeColor}
                              badgeText={route.badgeText}
                              external={category.external}
                            />
                          )
                        );
                      })}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      external={category.external}
                    />
                  )}
                </React.Fragment>
              )
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default connect((store) => ({
  sidebar: store.sidebar,
  user: store.session,
}))(Sidebar);
