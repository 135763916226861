import { all } from "redux-saga/effects";
import watchLoginAsync from "./sessionSaga";
import watchDocumentsAsync from "./documentsSaga";
import watchHouseholdsAsync from "./householdsSaga";

const sagas = [
  ...watchLoginAsync,
  ...watchDocumentsAsync,
  ...watchHouseholdsAsync,
];

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all(sagas);
}
