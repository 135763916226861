import { useDispatch } from "react-redux";
import { getMyDocuments, uploadFile } from "Actions/documentsActions";
import { newError, clearError } from "Actions/errorsActions";
import { getMyHouseholds, setHousehold } from "Actions/householdActions";

import {
  enableModernTheme,
  enableClassicTheme,
  enableDarkTheme,
  enableLightTheme,
} from "Actions/themeActions";

import {
  login,
  uploadAvatar,
  signup,
  updateProfile,
  logout,
  authenticate,
  unauthenticate,
  smsConfirmationRequest,
  confirmSmsRequest,
  resetPassword,
  requestPasswordReset,
  sendOTP,
  verifyOTP
} from "Actions/sessionActions";

import {
  toggleSidebar,
  showSidebar,
  hideSidebar,
  enableRightSidebar,
  disableRightSidebar,
} from "Actions/sidebarActions";

const useReduxActions = () => {
  const dispatch = useDispatch();
  return {
    getMyDocuments: (data) => dispatch(getMyDocuments(data)),
    uploadFile: (data) => dispatch(uploadFile(data)),
    newError: (data) => dispatch(newError(data)),
    clearError: (data) => dispatch(clearError(data)),
    getMyHouseholds: (data) => dispatch(getMyHouseholds(data)),
    setHousehold: (data) => dispatch(setHousehold(data)),
    login: (data, history) => dispatch(login(data, history)),
    uploadAvatar: (data) => dispatch(uploadAvatar(data)),
    signup: (data, history) => dispatch(signup(data, history)),
    updateProfile: (data) => dispatch(updateProfile(data)),
    logout: (data) => dispatch(logout(data)),
    authenticate: (data) => dispatch(authenticate(data)),
    unauthenticate: (data) => dispatch(unauthenticate(data)),
    smsConfirmationRequest: (data) => dispatch(smsConfirmationRequest(data)),
    confirmSmsRequest: (data) => dispatch(confirmSmsRequest(data)),
    toggleSidebar: (data) => dispatch(toggleSidebar(data)),
    showSidebar: (data) => dispatch(showSidebar(data)),
    hideSidebar: (data) => dispatch(hideSidebar(data)),
    enableRightSidebar: (data) => dispatch(enableRightSidebar(data)),
    disableRightSidebar: (data) => dispatch(disableRightSidebar(data)),
    enableModernTheme: (data) => dispatch(enableModernTheme(data)),
    enableClassicTheme: (data) => dispatch(enableClassicTheme(data)),
    enableDarkTheme: (data) => dispatch(enableDarkTheme(data)),
    enableLightTheme: (data) => dispatch(enableLightTheme(data)),
    resetPassword: (data, history) => dispatch(resetPassword(data, history)),
    requestPasswordReset: (data, history) =>
      dispatch(requestPasswordReset(data, history)),
    sendOTP: (data) => dispatch(sendOTP(data)),
    verifyOTP: (data, history) => dispatch(verifyOTP(data, history))
  };
};

export default useReduxActions;
