import axios from "axios";

const API =
  process.env.NODE_ENV === "development" ? "http://localhost:4000/api" : "/api";

function headers() {
  const token = JSON.parse(window.store.get("token"));
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer: ${token}`,
  };
}

function queryString(params) {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");
  return `${query.length ? "?" : ""}${query}`;
}

export default {
  fetch(url, params = {}) {
    const fullUrl = `${API}${url}${queryString(params)}`;
    return axios.get(fullUrl, {
      headers: headers(),
    });
  },

  post(url, data) {
    return axios
      .post(`${API}${url}`, data, { headers: headers() })
      .catch((error) => error.response);
  },

  put(url, data) {
    return axios
      .put(`${API}${url}`, data, { headers: headers() })
      .catch((error) => error.response);
  },

  patch(url, data) {
    return axios.patch(`${API}${url}`, data, { headers: headers() });
  },

  delete(url) {
    return axios.delete(`${API}${url}`, { headers: headers() });
  },
};
