import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Main from "../components/Main";
import useReduxActions from "Hooks/useReduxActions";
const Landing = ({ children, theme }) => {
  const dispatch = useDispatch();
  const { enableModernTheme } = useReduxActions();

  useEffect(() => {
    if (theme.currentThemeName !== "modern") {
      enableModernTheme();
      dispatch({ type: "@APP/RESET" });
    }
  }, []);

  return <Main>{children}</Main>;
};

export default connect((store) => ({
  theme: store.theme,
}))(Landing);
