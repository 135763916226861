import React, { useEffect } from "react";
import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { connect, useDispatch } from "react-redux";

import useReduxActions from "Hooks/useReduxActions";
const Settings = ({ children, theme, history }) => {
  const { enableModernTheme } = useReduxActions();
  useEffect(() => {
    if (theme.currentThemeName !== "modern") {
      enableModernTheme();
    }
  }, []);

  return (
    <React.Fragment>
      <Wrapper>
        <Main>
          <Navbar history={history} showSidebar={false} />
          <Content>{children}</Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default connect((store) => ({
  theme: store.theme,
}))(Settings);
