import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import "Components/i18n";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV === "development") {
  // do nothing
} else {
  Sentry.init({
    dsn:
      "https://3f72b211e57948e0a8c3fc0d3335cb1d@o516000.ingest.sentry.io/5621781",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// Render app in `#root` element
ReactDOM.render(
  // All translation files are loaded asynchronously
  // to this React application. In this case, while we wait for the translation files,
  // we render just nothing. If you want to provide a fallback component,
  // for example a loading indicator, use the fallback property of the Suspense component.
  <Suspense fallback={null}>
    <App />
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
