import { API_HOUSEHOLD } from "../constants";

export const getMyHouseholds = (data) => ({
  type: API_HOUSEHOLD.REQUEST,
  data,
});

export const setHousehold = (data) => ({
  type: API_HOUSEHOLD.SET,
  data,
});
