import React from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";

const FileUploader = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dropzone onDrop={(acceptedFiles) => props.changeHandler(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              <center>
                {props.children}
                {props.isFilePicked ? (
                  <p>{props.selectedFile.name}</p>
                ) : (
                  <p>{t("file_uploader_helper")} </p>
                )}
                <Button onClick={() => console.log} color="primary">
                  <FontAwesomeIcon icon={faUpload} />{" "}
                  {t("documents.upload_button")}{" "}
                </Button>
              </center>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default FileUploader;
