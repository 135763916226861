import { createActionTypes } from "Utils/index";

export default {
  USERS: createActionTypes("API_ADMIN_USERS", [
    "GET",
    "SUCCESS",
    "FAILURE",
    "SET",
    "SEARCH",
  ]),
  HOUSEHOLDS: createActionTypes("API_ADMIN_HOUSEHOLDS", [
    "GET",
    "SUCCESS",
    "FAILURE",
    "SET",
  ]),
  HOUSEHOLD: createActionTypes("API_ADMIN_HOUSEHOLD", [
    "GET",
    "SUCCESS",
    "FAILURE",
    "SET",
  ]),
  DOCUMENTS: createActionTypes("API_ADMIN_DOCUMENTS", [
    "GET",
    "SUCCESS",
    "FAILURE",
    "SET",
  ]),
  DOCUMENT: createActionTypes("API_ADMIN_DOCUMENT", [
    "APPROVE",
    "REJECT",
    "ANNOTATE",
    "CREATE",
    "ARCHIVE",
  ]),
  FILE: createActionTypes("API_ADMIN_FILE", [
    "APPROVE",
    "REJECT",
    "ANNOTATE",
    "ASK_TO_SIGN",
    "CREATE",
    "ARCHIVE",
    "UPLOAD",
  ]),
};
