import React from 'react'
import { useTranslation } from "react-i18next";
import { Button, CustomInput, Form, FormGroup, Input, Label } from "reactstrap"

export const EmailSignInForm = ({ onSubmit, handleChange, isLoading }) => {
    const { t } = useTranslation();

    return (
        <Form onSubmit={onSubmit}>
            <FormGroup>
                <Label>Email</Label>
                <Input
                    bsSize="lg"
                    type="email"
                    name="email"
                    onChange={handleChange("email")}
                    placeholder={t("Enter your e-mail")}
                    required
                />
            </FormGroup>
            <div>
                <CustomInput
                    type="checkbox"
                    id="rememberMe"
                    onChange={handleChange("rememberme")}
                    label={t("Remember me next time")}
                    defaultChecked
                />
            </div>
            <div className="text-center mt-3">
                <Button type="submit" color="primary" size="lg" disabled={isLoading}>
                    {!isLoading ? t("Sign in") : t("loading")}
                </Button>
            </div>
        </Form>
    )
}
