import Horizon65Logo from "Assets/img/brands/logo.svg";
import Error from "Components/Errors";
import useReduxActions from "Hooks/useReduxActions";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form, FormGroup, Input, Label
} from "reactstrap";

const handleSubmit = (data, functionHandler, history) => (event) => {
  event.preventDefault();
  functionHandler({ ...data, as_lead: true }, history);
};

const pushHistory = (history, path) => history.push(path);

const SignUp = ({ errors, loading, hasSignUpFinished }) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const partnerCode = urlParams.get("partner")
  const title = urlParams.get("title")
  const subtitle = urlParams.get("subtitle")
  const logo = urlParams.get("logo")

  const { i18n } = useTranslation();
  const { signup, clearError } = useReduxActions();
  let history = useHistory();
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    terms_of_service_accepted_at: null,
    language: i18n.language,
    partner_code: partnerCode ?? "internal_dashboard",
    app_version: "web_app",
  });
  const { t } = useTranslation();

  const handleChange = useCallback((fieldname) => (event) => {
    typeof event?.persist === 'function' && event.persist()
    setUser(user => ({ ...user, [fieldname]: event?.target?.value ?? event }))
  }, []);

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <img src={logo || Horizon65Logo} style={{ marginBottom: 20, maxWidth: 300, width: '80%' }} alt="Horizon65 logo" />
        <h1 className="h2">{title || t("login.get_started")}</h1>
        <p className="lead">{subtitle || t("login.start_using_horizon65_today")}</p>
      </div>

      <Card>
        <CardBody>
          {!hasSignUpFinished ? (
            <>
              <Error errors={errors} clearError={clearError} />
              <div className="m-sm-4">
                <Form onSubmit={handleSubmit({ ...user, phone: `+${user.phone}` }, signup, history)}>
                  <FormGroup>
                    <Label>{t("login.name")}</Label>
                    <Input
                      bsSize="lg"
                      type="text"
                      name="name"
                      onChange={handleChange("first_name")}
                      placeholder={t("login.name")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{t("login.surname")}</Label>
                    <Input
                      bsSize="lg"
                      type="text"
                      name="company"
                      onChange={handleChange("last_name")}
                      placeholder={t("login.surname")}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>{t("phone_number")}</Label>
                    <PhoneInput
                      inputProps={{
                        className: "big-phone-input",
                        name: "phone",
                        required: true,
                      }}
                      country={"de"}
                      inputStyle={{ border: '1px solid #ced4da', borderRadius: '0.3rem' }}
                      onChange={handleChange("phone")}
                    />
                  </FormGroup>

                  <FormGroup check>
                    <Label check>
                      <Input
                        value={!!user.terms_of_service_accepted_at}
                        onChange={() => handleChange("terms_of_service_accepted_at")(user.terms_of_service_accepted_at ? null : new Date())}
                        type="checkbox"
                      />
                      <a style={{ color: 'black' }} href="https://www.horizon65.com/privacy-policy" target="_blank" rel="noreferrer">{t("login.accept_terms_and_conditions")}</a>
                    </Label>
                  </FormGroup>
                  <div className="text-center mt-3">
                    <Button type="submit" color="primary" size="lg" disabled={loading}>
                      {!loading ? t("Sign up") : t("loading")}
                    </Button>
                  </div>
                </Form>
              </div>
              <div className="text-center mt-3">
                <hr />
                <Button
                  onClick={() => pushHistory(history, "/auth/sign-in")}
                  color="grey"
                  size="lg"
                >
                  {t("Already created your account? Login")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="text-center">
                <h2 className="mt-4">{t("signup.we_have_sent")}</h2>
                <p className="mt-4">{t("signup.it_contains")}</p>
                <p>{t("signup.open_it")}</p>
              </div>
              <div className="text-center mt-3">
                <hr />
                <Button
                  onClick={() => pushHistory(history, "/dashboard")}
                  color="grey"
                  size="lg"
                >
                  {t("signup.go_back")}
                </Button>
              </div>
            </>
          )}

        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStateProps = (state) => ({
  user: state.user,
  errors: state.errors,
  loading: state.session.loading,
  hasSignUpFinished: state.session.leadCreated
});

export default connect(mapStateProps, null)(SignUp);
