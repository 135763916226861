import types from "./actionTypes";

export const useActions = (_state, dispatch) => ({
  loadHouseholds: (data) => {
    dispatch({ type: types.HOUSEHOLDS.GET, data });
  },
  loadUsers: (data) => {
    dispatch({ type: types.USERS.GET, data });
  },
  searchUsers: (data) => {
    dispatch({ type: types.USERS.SEARCH, data });
  },
  loadDocuments: (data) => {
    dispatch({ type: types.DOCUMENTS.GET, data });
  },
  approveDocument: (data) => {
    dispatch({ type: types.DOCUMENT.APPROVE, data });
  },
  rejectDocument: (data) => {
    dispatch({ type: types.DOCUMENT.REJECT, data });
  },
  annotateDocument: (data) => {
    dispatch({ type: types.DOCUMENT.ANNOTATE, data });
  },
  createDocument: (data) => {
    dispatch({ type: types.DOCUMENT.CREATE, data });
  },
  archiveDocument: (data) => {
    dispatch({ type: types.DOCUMENT.ARCHIVE, data });
  },
  archiveFile: (data) => {
    dispatch({ type: types.FILE.ARCHIVE, data });
  },
  approveFile: (data) => {
    dispatch({ type: types.FILE.APPROVE, data });
  },
  rejectFile: (data) => {
    dispatch({ type: types.FILE.REJECT, data });
  },
  uploadFile: (data) => {
    dispatch({ type: types.FILE.UPLOAD, data });
  },
  annotateFile: (data) => {
    dispatch({ type: types.FILE.ANNOTATE, data });
  },
  setHousehold: (data) => {
    dispatch({ type: types.HOUSEHOLD.SET, data });
  },
  getHousehold: (data) => {
    dispatch({ type: types.HOUSEHOLD.GET, data });
  },
  askToSign: (data) => {
    dispatch({ type: types.FILE.ASK_TO_SIGN, data });
  },
});
