import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import moment from "moment";

i18n
  .use(LanguageDetector)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)

  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      // order and from where user language should be detected
      order: [
        "path",
        "querystring",
        "htmlTag",
        "subdomain",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
      ],

      // keys or params to lookup language from
      lookupQuerystring: "language",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: "/", sameSite: "strict" },
    },
    debug:
      process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test",

    fallbackLng: "en",
    whitelist: ["en", "de"],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === "uppercase") return value.toUpperCase();
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    saveMissing: false,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
