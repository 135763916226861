import * as types from "../constants";

export const login = (data, history) => ({
  type: types.LOGIN_REQUEST,
  data,
  history,
});

export const uploadAvatar = (data) => ({
  type: types.UPLOAD_AVATAR_REQUEST,
  data,
});

export const signup = (data, history) => ({
  type: types.SIGNUP_REQUEST,
  data,
  history,
});

export const updateProfile = (data) => ({
  type: types.UPDATE_PROFILE_REQUEST,
  data,
});

export const resetPassword = (data, history) => ({
  type: types.RESET_PASSWORD_REQUEST,
  data,
  history,
});

export const requestPasswordReset = (data, history) => ({
  type: types.REQUEST_RESET_PASSWORD_REQUEST,
  data,
  history,
});
export const logout = (history) => ({ type: types.LOGOUT, history });
export const authenticate = () => ({ type: types.AUTHENTICATION_REQUEST });
export const unauthenticate = () => ({ type: types.AUTHENTICATION_FAILURE });

export const smsConfirmationRequest = () => ({
  type: types.SMS_CONFIRMATION_REQUEST,
});

export const confirmSmsRequest = (data) => ({
  type: types.CONFIRM_SMS_REQUEST,
  data,
});

export const sendOTP = (data) => ({
  type: types.SEND_OTP_REQUEST,
  data,
})

export const verifyOTP = (data, history) => ({
  type: types.VERIFY_OTP_REQUEST,
  data,
  history,
})
