import React, { createContext, useReducer } from "react";
import reducers from "./reducers";
import { useActions } from "./action";
import { applyMiddleware } from "./middleware";

const initialState = {};
for (const [key, reducer] of Object.entries(reducers)) {
  // Initialize the reducers with their default values
  // by simply calling them and adding their
  // default state in the object
  initialState[key] = reducer();
}

// Utility to have one reducer responsible
// for one key in the store
const combineReducers = (slices) => (state, action) =>
  Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    }),
    state
  );

const rootReducer = combineReducers(reducers);

const AdminStoreContext = createContext();

const AdminStoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const actions = useActions(state, applyMiddleware(dispatch));
  return (
    <AdminStoreContext.Provider value={{ state, actions }}>
      {children}
    </AdminStoreContext.Provider>
  );
};

export { AdminStoreContext, AdminStoreProvider };
