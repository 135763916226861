import React from 'react'
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import { Button, CustomInput, Form, FormGroup, Input, Label } from "reactstrap"

export const PhoneSignInForm = ({ onSubmit, handleChange, isLoading }) => {
    const { t } = useTranslation();

    return (
        <Form onSubmit={onSubmit} >
            <FormGroup>
                <Label>{t("phone_number")}</Label>
                <PhoneInput
                    inputProps={{
                        className: "big-phone-input",
                        name: "phone",
                        required: true,
                    }}
                    country={"de"}
                    inputStyle={{ border: '1px solid #ced4da', borderRadius: '0.3rem' }}
                    onChange={handleChange("phone")}
                />
            </FormGroup>
            <div>
                <CustomInput
                    type="checkbox"
                    id="rememberMe"
                    onChange={handleChange("rememberme")}
                    label={t("Remember me next time")}
                    defaultChecked
                />
            </div>
            <div className="text-center mt-3">
                <Button type="submit" color="primary" size="lg" disabled={isLoading}>
                    {!isLoading ? t("Sign in") : t("loading")}
                </Button>
            </div>
        </Form>
    )
}