import async from "../components/Async";

import {
  faFile,
  faHome,
  faSignInAlt,
  faMobileAlt,
  faStore,
} from "@fortawesome/free-solid-svg-icons";

// Landing
import Landing from "../pages/landing/Landing";

// Auth
import SignIn from "../pages/auth/SignIn";
import VerifyEmail from "../pages/auth/VerifyEmail";
import PasswordReset from "../pages/auth/PasswordReset";
import RequestPasswordReset from "../pages/auth/RequestPasswordReset";
import SignUp from "../pages/auth/SignUp";
import PartnershipForm from "../pages/partnership/Form";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Admin
import AdminUsers from "../pages/pages/admin/Users";
import AdminHouseholds from "../pages/pages/admin/Households";
import AdminHousehold from "../pages/pages/admin/Household";
import AdminDocuments from "../pages/pages/admin/Documents";

// Pages
const Settings = async(() => import("../pages/pages/Settings"));
const SMSSettings = async(() => import("../pages/pages/SMSSettings"));
const Documents = async(() => import("../pages/pages/Documents"));
const DownloadApp = async(() => import("../pages/pages/DownloadApp"));

// Routes
const landingRoutes = {
  path: "/",
  name: "Landing Page",
  component: Landing,
  children: null,
};

const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  header: "Main",
  icon: faHome,
  component: Documents,
  children: null,
};

const documentsRoutes = (household) => {
  return {
    path: `/pages/${household.id}/documents`,
    name: `Documents`,
    icon: faFile,
    component: Documents,
    children: null,
  };
};

const marketingRoutes = () => {
  return {
    path: `/pages/download-app`,
    name: `Download app`,
    icon: faMobileAlt,
    component: DownloadApp,
    children: null,
  };
};

export const sidebarRoutes = [documentsRoutes, marketingRoutes];

const settingsRoutes = {
  path: "/pages",
  name: "Account",
  icon: faFile,
  children: [
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings,
    },
    {
      path: "/pages/settings/sms",
      name: "Sms Settings",
      component: SMSSettings,
    },
  ],
};

const pageRoutes = {
  path: "/pages",
  name: "Account",
  icon: faFile,
  children: [
    {
      path: "/pages/:householdId/documents",
      name: "Documents",
      component: Documents,
    },
    {
      path: "/pages/download-app",
      name: "Download app",
      component: DownloadApp,
    },
  ],
};

const formsRoutes = {
  path: "/forms",
  name: "forms",
  children: [
    {
      path: "/:language?/partnership/:partner",
      name: "Partnership",
      component: PartnershipForm,
    },
  ],
};

// verify_email/success
const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/:language?/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/:language?/verify_email/success",
      name: "Verify E-mail",
      component: VerifyEmail,
    },
    {
      path: "/:language?/reset-password/:token",
      name: "Password Reset",
      component: PasswordReset,
    },
    {
      path: "/:language?/request-reset-password",
      name: "Password Reset",
      component: RequestPasswordReset,
    },
    {
      path: "/:language?/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/:language?/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/:language?/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
};

// This route is not visisble unless you are an admin
const adminRoutes = {
  path: "/admin",
  name: "Admin",
  sidebar: true,
  icon: faFile,
  children: [
    {
      path: "/admin",
      name: "Users",
      component: AdminUsers,
      icon: faFile,
      sidebar: true,
    },
    {
      path: "/search/:query",
      name: "Users",
      component: AdminUsers,
      sidebar: false,
    },
    {
      path: "/admin/households",
      name: "Households",
      icon: faFile,
      component: AdminHouseholds,
      sidebar: true,
    },
    {
      path: "/admin/household/:householdId/documents",
      icon: faFile,
      name: "Documents",
      component: AdminDocuments,
    },
    {
      path: "/admin/household/:householdId/show",
      icon: faFile,
      name: "Household",
      component: AdminHousehold,
    },
  ],
};

const warehouseLink = {
  path: "/warehouse/login",
  name: "Warehouse",
  sidebar: true,
  icon: faStore,
  external: true
}

// Dashboard specific routes
export const dashboard = [dashboardRoutes, pageRoutes];
export const settings = [settingsRoutes];

// Landing specific routes
export const landing = [landingRoutes];

// Admin specific routes
export const admin = [adminRoutes, warehouseLink];

// Auth specific routes
export const page = [authRoutes];

// Form specific routes
export const forms = [formsRoutes];

// All routes
export default [dashboardRoutes, pageRoutes];
