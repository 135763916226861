import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import useError from "Hooks/useError";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Row,
  Label,
  Col,
} from "reactstrap";

import { AdminStoreContext } from "Admin/store";

const statuses = [
  { value: "requested", label: "requested" },
  { value: "rejected", label: "rejected" },
  { value: "approved", label: "approved" },
  { value: "archived", label: "archived" },
];

const AdminNewDocument = (props) => {
  const { state, actions } = useContext(AdminStoreContext);
  const { householdId } = props;
  const createDocument = actions.createDocument;
  const [newDoc, setNewDoc] = useState({ householdId: householdId });

  const handleSubmit = (e, _data) => {
    e.preventDefault();
    e.target.reset();
    createDocument(newDoc);
    setNewDoc((_newDoc) => Object.assign({}, { householdId: householdId }, {}));
  };

  const handleChange = (name) => (event) => {
    let value = event.target.value;
    setNewDoc((newDoc) => Object.assign({}, newDoc, { [name]: value }));
  };

  const handleChangeSelect = (name) => ({ value }) => {
    setNewDoc((newDoc) => Object.assign({}, newDoc, { [name]: value }));
  };

  useError(state.household.error);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Request new documents</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={(e) => handleSubmit(e, newDoc)}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="inputUsername">Document name</Label>
                <Input
                  onChange={handleChange("name")}
                  type="text"
                  id="name"
                  placeholder="Document name"
                />
              </FormGroup>
              <FormGroup>
                <Label>Status</Label>
                <Select
                  defaultValue="requested"
                  onChange={handleChangeSelect("state")}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={statuses}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Policy number</Label>
                <Input
                  onChange={handleChange("code")}
                  type="text"
                  id="code"
                  placeholder="Policy number"
                />
              </FormGroup>
              <FormGroup>
                <Label for="inputBio">Notes</Label>
                <Input
                  onChange={handleChange("note")}
                  type="textarea"
                  rows="2"
                  id="inputBio"
                  placeholder="Add some notes for this product, it will be visible to the user"
                />
              </FormGroup>
            </Col>
          </Row>
          <Button onSubmit={(e) => handleSubmit(e, newDoc)} color="primary">
            Add document
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};
export default AdminNewDocument;
