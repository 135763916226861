import React, { useEffect, useContext } from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Container,
  Form,
  Row,
} from "reactstrap";

import { faExternalLinkAlt as file } from "@fortawesome/free-solid-svg-icons";

import Header from "Components/Header";
import HeaderTitle from "Components/HeaderTitle";
import sortById from "Utils/index";
import { AdminStoreContext } from "Admin/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Households = (props) => {
  function viewDocuments(householdId) {
    const win = window.open(
      `/admin/household/${householdId}/documents`,
      "_blank"
    );
    win.focus();
  }
  function view(householdId) {
    const win = window.open(`/admin/household/${householdId}/show`, "_blank");
    win.focus();
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Households
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Form>
          <Row>
            <Col md="12">
              <br />
              <Table striped>
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>Name</th>
                    <th style={{ width: "33%" }}>Documents</th>
                  </tr>
                </thead>
                <tbody>
                  {props.households.sort(sortById).map((value) => {
                    return (
                      <tr key={value.id}>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => view(value.id)}
                        >
                          {value.name}
                        </td>
                        <td className="table-action">
                          View{"  "}
                          <FontAwesomeIcon
                            icon={file}
                            style={{ cursor: "pointer" }}
                            onClick={() => viewDocuments(value.id)}
                            fixedWidth
                            className="align-middle"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

const HouseholdsPage = () => {
  const { state, actions } = useContext(AdminStoreContext);

  useEffect(() => {
    actions.loadHouseholds();
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Households</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/admin">Admin</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Households</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col md="12" xl="12">
          <Households households={state.households?.data || []} />
        </Col>
      </Row>
    </Container>
  );
};

export default HouseholdsPage;
