import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import useReduxActions from "Hooks/useReduxActions";

const Dashboard = ({ sidebar, children, history, theme }) => {
  const { enableModernTheme, getMyHouseholds } = useReduxActions();
  const dispatch = useDispatch();

  useEffect(() => {
    if (theme.currentThemeName !== "modern") {
      enableModernTheme();
      dispatch({ type: "@APP/RESET" });
    }
    getMyHouseholds({});
  });

  return (
    <React.Fragment>
      <Wrapper>
        {!sidebar.isOnRight && <Sidebar />}
        <Main>
          <Navbar history={history} />
          <Content>{children}</Content>
          <Footer />
        </Main>
        {sidebar.isOnRight && <Sidebar />}
      </Wrapper>
    </React.Fragment>
  );
};

export default connect((store) => ({
  sidebar: store.sidebar,
  theme: store.theme,
}))(Dashboard);
