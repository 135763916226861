import { createStore, applyMiddleware } from "redux";
import rootReducer from "Reducers/index";
import rootSaga from "Sagas/index";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
const sagaMiddleware = createSagaMiddleware();

window.store = {
  localStoreSupport: function () {
    try {
      return "localStorage" in window && window["localStorage"] !== null;
    } catch (e) {
      return false;
    }
  },
  set: function (name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
    } else {
      // eslint-disable-next-line
      var expires = "";
    }
    if (this.localStoreSupport()) {
      localStorage.setItem(name, value);
    } else {
      document.cookie = name + "=" + value + expires + "; path=/";
    }
  },
  get: function (name) {
    if (this.localStoreSupport()) {
      var ret = localStorage.getItem(name);
      switch (ret) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return ret;
      }
    } else {
      // cookie fallback
      /*
       * after adding a cookie like
       * >> document.cookie = "bar=test; expires=Thu, 14 Jun 2018 13:05:38 GMT; path=/"
       * the value of document.cookie may look like
       * >> "foo=value; bar=test"
       */
      var nameEQ = name + "="; // what we are looking for
      var ca = document.cookie.split(";"); // split into separate cookies
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]; // the current cookie
        while (c.charAt(0) === " ") c = c.substring(1, c.length); // remove leading spaces
        if (c.indexOf(nameEQ) === 0) {
          // if it is the searched cookie
          // eslint-disable-next-line
          var ret = c.substring(nameEQ.length, c.length);
          // making "true" and "false" a boolean again.
          switch (ret) {
            case "true":
              return true;
            case "false":
              return false;
            default:
              return ret;
          }
        }
      }
      return null; // no cookie found
    }
  },
  del: function (name) {
    if (this.localStoreSupport()) {
      localStorage.removeItem(name);
    } else {
      this.set(name, "", -1);
    }
  },
};

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware)
    // other store enhancers if any
  )
);

sagaMiddleware.run(rootSaga);

export default store;
