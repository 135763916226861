import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "Components/Loader";
import { useTranslation } from "react-i18next";

import {
  landing as landingRoutes,
  dashboard as dashboardRoutes,
  admin as adminRoutes,
  page as pageRoutes,
  settings as settingsRoutes,
  forms as formRoutes
} from "./index";

import { connect } from "react-redux";
import { authenticate } from "Actions/sessionActions";
import DashboardLayout from "Layouts/Dashboard";
import AdminLayout from "Layouts/Admin";
import LandingLayout from "Layouts/Landing";
import SettingsLayout from "Layouts/Settings";
import AuthLayout from "Layouts/Auth";
import FormLayout from "Layouts/Form";
import Page404 from "Pages/auth/Page404";
import SigninPage from "Pages/auth/SignIn";
import ScrollToTop from "Components/ScrollToTop";

const isUserLogged = (props) => props.isLogged === true;
const isUserAdmin = (props) => props.isLogged && props.session.admin === true;
const unfoldRoutes = (Layout, routes) => {
  return routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );
};

const routesWithGuard = (guardFunction, Layout, routes, props) => {
  if (guardFunction(props)) {
    return unfoldRoutes(Layout, routes, props);
  } else {
    return (
      <Route
        render={() => (
          <AuthLayout>
            <SigninPage />
          </AuthLayout>
        )}
      />
    );
  }
};

const Routes = (props) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const handleChangeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  const { authenticate } = props;
  const token = window.store.get("token");

  useEffect(() => {
    handleChangeLanguage(props.session.language || currentLanguage);
  }, [props.session.language]);

  useEffect(() => {
    const token = window.store.get("token");
    if (token) {
      authenticate();
    }
  }, []);

  if (token && !props.isLogged) {
    // avoid showing anything until this state is consistent
    // if the token is present it will try to authenticate
    // if the authentication fails, the token will be deleted from the store
    // and the condition will not hold true anymore, showing either
    // the login page or the right component for the logged user
    return <Loader />;
  }

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {unfoldRoutes(LandingLayout, landingRoutes, props)}
          {unfoldRoutes(AuthLayout, pageRoutes, props)}
          {unfoldRoutes(FormLayout, formRoutes, props)}
          {routesWithGuard(isUserLogged, SettingsLayout, settingsRoutes, props)}
          {routesWithGuard(
            isUserLogged,
            DashboardLayout,
            dashboardRoutes,
            props
          )}
          {routesWithGuard(isUserAdmin, AdminLayout, adminRoutes, props)}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  authenticate: () => dispatch(authenticate()),
});

const mapStateProps = (state) => ({
  session: state.session,
  isLogged: state.session.isLogged,
});

export default connect(mapStateProps, mapDispatchToProps)(Routes);
