import React from "react";

import { UncontrolledAlert } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";

const toggle = (id, dismissFun) => () => {
  dismissFun(id);
};

const Error = ({ errors, clearError }) => {
  if (errors === {}) {
    return;
  }
  return Object.entries(errors).map((id) => (
    <UncontrolledAlert
      toggle={toggle(id[0], clearError)}
      key={id[0]}
      color="danger"
      className="alert-outline"
    >
      <div className="alert-icon">
        <FontAwesomeIcon icon={faBell} fixedWidth />
      </div>
      <div className="alert-message">
        <strong>{id[1]}</strong>
      </div>
    </UncontrolledAlert>
  ));
};

// Set default props
Error.defaultProps = {
  errors: {},
  clearError: () => {},
};

export default Error;
