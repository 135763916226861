import * as types from "../constants";
const initialState = {
  email: "",
  token: false,
  isLogged: false,
  loading: false,
  hasSentOTP: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
    case types.SIGNUP_REQUEST:
    case types.SEND_OTP_REQUEST:
    case types.VERIFY_OTP_REQUEST:
    case types.AUTHENTICATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SEND_OTP_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasSentOTP: true
      };
    case types.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        ...action.response.data.user,
        isLogged: true,
        loading: false,
        hasSentOTP: false
      };
    case types.AUTHENTICATION_FAILURE:
      return {
        ...state,
        isLogged: false,
        loading: false,
        hasSentOTP: false,
      };
    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        leadCreated: true,
        hasSentOTP: false
      }
    case types.SEND_OTP_REQUEST_FAILURE:
    case types.SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        hasSentOTP: false
      }
    case types.LOGOUT:
      return {
        ...initialState,
        hasSentOTP: false
      };
    default:
      return state;
  }
}
