import React, { useEffect, useContext } from "react";

import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Container,
  Form,
  Row,
} from "reactstrap";

import { faExternalLinkAlt as file } from "@fortawesome/free-solid-svg-icons";

import Header from "Components/Header";
import HeaderTitle from "Components/HeaderTitle";
import { AdminStoreContext } from "Admin/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Documents = ({ householdId, documents }) => {
  function viewDocuments(householdId) {
    const win = window.open(
      `/admin/household/${householdId}/documents`,
      "_blank"
    );
    win.focus();
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">
          Documents
          {"  "}
          <FontAwesomeIcon
            icon={file}
            style={{ cursor: "pointer", width: 12 }}
            onClick={() => viewDocuments(householdId)}
            fixedWidth
            className="align-middle"
          />
        </CardTitle>
      </CardHeader>
      <Table size="sm" striped>
        <thead>
          <tr>
            <th className="text-left">Name</th>
            <th className="text-left">Status</th>
            <th className="text-right">Files</th>
          </tr>
        </thead>
        <tbody>
          {documents &&
            documents.map((value) => {
              return (
                <tr>
                  <td className="text-left">{value.name}</td>
                  <td className="text-left">{value.state}</td>
                  <td className="text-right">{value.files.length}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Card>
  );
};
const Users = ({ users }) => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Users</CardTitle>
    </CardHeader>
    <Table size="sm" striped>
      <thead>
        <tr>
          <th className="text-left">Name</th>
          <th className="text-left">Email</th>
          <th className="text-right">Phone</th>
        </tr>
      </thead>
      <tbody>
        {users &&
          users.map((value) => {
            return (
              <tr>
                <td className="text-left">
                  {value.first_name} {value.last_name}
                </td>
                <td className="text-left">{value.email}</td>
                <td className="text-right">{value.phone}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  </Card>
);
const HouseholdPage = () => {
  const { state, actions } = useContext(AdminStoreContext);
  let { householdId } = useParams();
  let household = state.household.data;
  useEffect(() => {
    actions.getHousehold({ householdId });
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Household: {household.name}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/admin">Admin</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Households</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{household.name}</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <Users users={household.users} />
            </Col>
            <Col md="6">
              <Documents
                householdId={household.id}
                documents={household.documents}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default HouseholdPage;
