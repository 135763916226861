import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Error from "../../components/Errors";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import useReduxActions from "Hooks/useReduxActions";
import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const handleChange = (user) => (name) => (event) => {
  user[name] = event.target.value;
};

const handleSubmit = (data, functionHandler, history) => (event) => {
  event.preventDefault();
  functionHandler(data, history);
};

const pushHistory = (history, path) => history.push(path);

const PasswordReset = ({ errors = {}, actions = useReduxActions }) => {
  const params = useParams();
  const { token } = params;
  const [data] = useState({
    code: "",
    token: token,
    new_password: "",
    new_password_repeat: "",
  });
  const { t } = useTranslation();
  const history = useHistory();
  const { resetPassword, clearError } = actions();

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>{t("password_reset.title")}</h2>
        <p className="lead"></p>
      </div>

      <Card>
        <CardBody>
          <Error errors={errors} clearError={clearError} />
          <div className="m-sm-4">
            <Form onSubmit={handleSubmit(data, resetPassword, history)}>
              <FormGroup>
                <Label>{t("password_reset.new_password_label")}</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="password"
                  onChange={handleChange(data)("new_password")}
                  placeholder={t("password_reset.new_password_placeholder")}
                />
              </FormGroup>
              <div className="text-center mt-3">
                <Button type="submit" color="primary" size="lg">
                  {t("password_reset.button_text")}
                </Button>
              </div>
            </Form>
          </div>
          <div className="text-center mt-3">
            <hr />
            <Button
              onClick={() => pushHistory(history, "/auth/sign-in")}
              color="grey"
              size="lg"
            >
              {t("password_reset.sign_in_button_text")}
            </Button>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStateProps = (state) => ({ errors: state.errors });

export default connect(mapStateProps, null)(PasswordReset);
