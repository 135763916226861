import { put, takeEvery } from "redux-saga/effects";
import api from "Utils/api";
import { toastr } from "react-redux-toastr";
import { API_HOUSEHOLD } from "../constants";
import { setHousehold } from "Actions/householdActions";

function* callGetAll({ data }) {
  const result = yield api.fetch("/user/households", data);
  data = result?.data?.households;
  if (data) {
    const [first, _] = data;
    yield put({
      type: API_HOUSEHOLD.SUCCESS,
      response: data,
    });
    yield put(setHousehold(first));
  } else {
    toastr.error("Error", result?.data?.errors);
  }
}

function* watchHouseholdsSaga() {
  yield takeEvery(API_HOUSEHOLD.REQUEST, callGetAll);
}

export default [watchHouseholdsSaga()];
