import { call, put, takeEvery } from "redux-saga/effects";
import api from "Utils/api";
import create_UUID from "Utils/uuid";
import * as sessionTypes from "../constants";
import { newError } from "Actions/errorsActions";
import { toastr } from "react-redux-toastr";
import { authenticate as authenticateAction } from "Actions/sessionActions";

function setCurrentUser(token) {
  window.store.set("token", JSON.stringify(token));
}

// Login

function* callSendOTP({ data }) {
  const result = yield api.post("/login", { ...data, passwordless: true });
  const uuid = yield call(create_UUID);
  if (result?.data?.data) {
    yield put({
      type: sessionTypes.SEND_OTP_REQUEST_SUCCESS,
      response: result.data,
    });
  } else {
    yield put({ type: sessionTypes.SEND_OTP_REQUEST_FAILURE });
    yield put(newError(result?.data?.errors || "Something went wrong", uuid));
    window.store.del("token");
  }
}

function* callVerifyOTP({ data, history }) {
  const result = yield api.post("/login", { ...data, passwordless: true });
  const uuid = yield call(create_UUID);
  if (result?.data?.data && result?.data?.meta?.token) {
    setCurrentUser(result.data.meta.token);
    yield put({
      type: sessionTypes.AUTHENTICATION_SUCCESS,
      response: result.data,
    });
    history.push("/dashboard");
  } else {
    yield put({ type: sessionTypes.AUTHENTICATION_FAILURE });
    yield put(newError(result?.data?.errors || "Something went wrong", uuid));
    window.store.del("token");
  }
}

// Upload avatar

function uploadAvatar(data) {
  const { file } = data;
  return api.post("/user/avatar", file);
}

function* callUploadAvatar({ data }) {
  const result = yield call(uploadAvatar, data);
  if (result?.data?.data && result?.data?.meta?.token) {
    setCurrentUser(result.data.meta.token);
    yield put({
      type: sessionTypes.AUTHENTICATION_SUCCESS,
      response: result.data,
    });
  }
}

// Signup

function* callSignup({ data, history }) {
  const result = yield api.post("/signup", data);

  console.log(result.data)

  if (result?.data?.lead?.id) {
    yield put({
      type: sessionTypes.SIGNUP_SUCCESS,
      lead: result.data.lead,
    });
    if (window.gtag) {
      window.gtag("event", "generate_lead", {
        currency: "EUR",
        value: 100,
      });
      window.fbq("track", "CompleteRegistration");
      window.fbq("track", "Lead");
    }
  } else {
    yield put({ type: sessionTypes.SIGNUP_FAILURE });
  }
}

// Logout

function* callLogout({ history }) {
  yield window.store.del("token");
  yield history.push("/");
}

// Authenticate

function* callAuthenticate() {
  try {
    const result = yield api.fetch("/user/me");
    if (result?.data?.data && result?.data?.meta?.token) {
      setCurrentUser(result.data.meta.token);
      yield put({
        type: sessionTypes.AUTHENTICATION_SUCCESS,
        response: result.data,
      });
    } else {
      yield put({ type: sessionTypes.AUTHENTICATION_FAILURE });
      yield put({ type: sessionTypes.NEW_ERROR, message: result.data.errors });
      window.store.del("token");
      window.location = "/auth/sign-in";
    }
  } catch (error) {
    window.store.del("token");
    window.location = "/auth/sign-in";
  }
}

// Update profile

function* calldoUpdateProfile({ data }) {
  const result = yield api.put("/user", data);
  if (result?.data?.data) {
    yield put({
      type: sessionTypes.AUTHENTICATION_SUCCESS,
      response: result.data,
    });
  } else {
    toastr.error("Error", result?.data?.errors);
  }
}

// Request SMS confirmation

function* callSmsVerification({ data }) {
  const result = yield api.post("/user/verification/sms/new", data);
  if (result?.data) {
    yield put(authenticateAction());
  } else {
    toastr.error("Error", result?.data?.errors);
  }
}

// SMS verification request

function* callSmsVerify({ data }) {
  const result = yield api.post("/user/verification/sms/verify", data);
  if (result?.data?.ok) {
    yield put(authenticateAction());
    toastr.info("Verified!");
  } else {
    toastr.error("Error", result?.data?.errors);
  }
}

function* callResetPassword({ data, history }) {
  const result = yield api.post("/reset-password", data);
  if (result?.status === 200) {
    history.push("/auth/sign-in");
  } else {
    toastr.error("Error", "Invalid data");
    history.push("/request-reset-password");
  }
}

function* callRequestResetPassword({ data, history }) {
  const result = yield api.post("/forgot-password", data);
  if (result?.status === 201) {
    toastr.info("Ok, check your e-mail");
  } else {
    toastr.error("Error", "Invalid data");
  }
}

function* watchSessionSagas() {
  yield takeEvery(sessionTypes.SEND_OTP_REQUEST, callSendOTP);
  yield takeEvery(sessionTypes.VERIFY_OTP_REQUEST, callVerifyOTP);
  yield takeEvery(sessionTypes.UPLOAD_AVATAR_REQUEST, callUploadAvatar);
  yield takeEvery(sessionTypes.SIGNUP_REQUEST, callSignup);
  yield takeEvery(sessionTypes.LOGOUT, callLogout);
  yield takeEvery(sessionTypes.AUTHENTICATION_REQUEST, callAuthenticate);
  yield takeEvery(sessionTypes.RESET_PASSWORD_REQUEST, callResetPassword);
  yield takeEvery(
    sessionTypes.REQUEST_RESET_PASSWORD_REQUEST,
    callRequestResetPassword
  );
  yield takeEvery(sessionTypes.UPDATE_PROFILE_REQUEST, calldoUpdateProfile);
  yield takeEvery("CHANGE_LANGUAGE", calldoUpdateProfile);
  yield takeEvery(sessionTypes.SMS_CONFIRMATION_REQUEST, callSmsVerification);
  yield takeEvery(sessionTypes.CONFIRM_SMS_REQUEST, callSmsVerify);
}

export default [watchSessionSagas()];
