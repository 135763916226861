import { useEffect } from "react";
import { toastr } from "react-redux-toastr";

const useError = (error) => {
  useEffect(() => {
    if (error !== "") {
      toastr.error("Error", error);
    }
  }, [error]);
};
export default useError;
